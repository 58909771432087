import request from '@/utils/request'

/**
 * 团队活动推荐列表
 * @param {*} data
 */
export function activeRecommendList(data) {
    return request({
        url: '/v2/activity/active/recommendList',
        method: 'post',
        params: data
    })
}


/**
 * 生成二维码
 * @param data
 */
export function genQRCode(data) {
    return request({
        url: '/v2/weixin/wxapp.general/getWxacodeUnlimit',
        method: 'post',
        params: data
    })

}

/**************文化团队********************/
/**
 * 团队标签
 * @param {*} data
 */
export function communityLableList(data) {
    return request({
        url: '/v2/culture/community.label/lists',
        method: 'post',
        params: data
    })
}

/**
 * 文化团队列表
 * @param data
 */
export function communityList(data) {
    return request({
        url: '/v2/culture/community.index/lists',
        method: 'post',
        params: data
    })
}
/**
 * 文化团队详情
 * @param data
 */
export function communityInfo(data) {
    return request({
        url: '/v2/culture/community.index/info',
        method: 'post',
        params: data
    })
}

/*******************************************/


/**
 * 友情链接
 * @param {*} data
 */
export function flinkList(data) {
    return request({
        url: '/v2/cms/links/list',
        method: 'post',
        params: data
    })
}


/**
 * 统计代码
 * @param data
 */
export function getVisit(aid) {
    return request({
        url: '/v2/cms/stat/visit?aid=' + aid,
        method: 'get'
    })
}


/**************文化志愿者********************/
/**
 * 文化志愿者列表
 * @param {*} data
 */
export function voluteerList(data) {
    return request({
        url: '/v2/culture/volunteer.team/pagelist',
        method: 'post',
        params: data
    })
}
/**
 * 文化志愿者详情
 * @param {*} data
 */
export function voluteerDetail(data) {
    return request({
        url: '/v2/culture/volunteer.team/detail',
        method: 'post',
        params: data
    })
}
/**
 * 文化志愿者团队活动列表
 * @param {*} data
 */
export function voluteerActiveList(data) {
    return request({
        url: '/v2/activity/active/pagelist',
        method: 'post',
        params: data
    })
}

/*******************************************/




/**************文化培训********************/
/**
 * 文化培训列表
 * @param {*} data
 */
export function courseList(data) {
    return request({
        url: '/v2/culture/course.index/pagelist',
        method: 'post',
        params: data
    })
}
/**
 * 文化培训详情
 * @param {*} data
 */
export function courseDetail(data) {
    return request({
        url: '/v2/culture/course.index/detail',
        method: 'post',
        params: data
    })
}

/**
 * 文化培训推荐
 * @param data
 */
export function courseRecommend(data) {
    return request({
        url: '/v2/culture/course.index/recommendList',
        method: 'post',
        params: data
    })
}

/*******************************************/


/**************文化点单********************/
/**
 * 文化点单列表
 * @param data
 */
export function serveList(data) {
    return request({
        url: '/v2/culture/serve.index/pagelist',
        method: 'post',
        params: data
    })
}

/**
 * 文化点单详情
 * @param data
 */
export function serveDetail(data) {
    return request({
        url: '/v2/culture/serve.index/detail',
        method: 'post',
        params: data
    })
}
/**
 * 文化点单类型
 * @param data
 */
export function serveCategoryList(data) {
    return request({
        url: '/v2/culture/attribute/getlist',
        method: 'post',
        params: data
    })
}


/*******************************************/



/**************文化日历********************/
/**
 * 用户按月获取参与的服务
 * @param data
 */
export function queryDateSchedule(data) {
    return request({
        url: '/v2/culture/calendar.index/dateSchedule',
        method: 'post',
        params: data
    })

}
/**
 * 按日程查询参与活动
 * @param data
 */
export function queryDaySchedule(data) {
    return request({
        url: '/v2/culture/calendar.index/daySchedule',
        method: 'post',
        params: data
    })

}
/*******************************************/


/**************文化直播********************/
/**
 * 直播列表
 * @param data
 */
export function queryLiveList(data) {
    return request({
        url: '/v2/culture/live.index/pagelist',
        method: 'post',
        params: data
    })

}

/**
 * 直播详情
 * @param data
 */
export function queryLiveDetail(data) {
    return request({
        url: '/v2/culture/live.index/detail',
        method: 'post',
        params: data
    })

}

/*******************************************/



/**************文化资源********************/
/**
 * 文化资源一级分类
 * @param data
 */
export function queryResourceCategory(data) {
    return request({
        url: '/v2/culture/resource.category/classes',
        method: 'post',
        params: data
    })
}
/**
 * 文化资源二级分类
 * @param data
 */
export function queryResourceChildCategory(data) {
    return request({
        url: '/v2/culture/resource.category/lists',
        method: 'post',
        params: data
    })
}
/**
 * 资源列表
 * @param data
 */
export function queryResourceList(data) {
    return request({
        url: '/v2/culture/resource/lists',
        method: 'post',
        params: data
    })
}
/**
 * 资源详情
 * @param data
 */
export function queryResourceDetail(data) {
    return request({
        url: '/v2/culture/resource/info',
        method: 'post',
        params: data
    })
}
export function queryResourceLike(data) {
    return request({
        url: '/v2/user/member.like/countSum',
        method: 'get',
        params: data
    })

}
export function queryResourceShare(data) {
    return request({
        url: '/v2/user/member.share/countSum',
        method: 'get',
        params: data
    })

}
export function queryResourceUp(data) {
    return request({
        url: '/v2/user/member.up/countSum',
        method: 'get',
        params: data
    })

}
/*******************************************/