<template>
  <div class="crumbs-container" v-if="id">
    <div class="crumbs-home" @click="home()">首页</div>
    <div class="crumbs-nav" v-for="item in crumbList" :key="item.id">
      <div class="crumbs-nav-slash">/</div>
      <div class="crumbs-nav-text" @click="nav(item)">
        {{ item.name }}
      </div>
    </div>
  </div>
</template>
<script>
import { infoList as cmsInfo, navinfo } from "@/api/header/header";
import { navList } from "@/api/header/header";
import getQueryUrl from "../../utils/util.js";
export default {
  data() {
    return {
      id: "",
      cid: "",
      crumbList: [],
      idList: [],
      navList: [],
      list: [],
      leaf: [],
      aid: process.env.VUE_APP_AID,
    };
  },
  watch: {
    $route: {
      handler(val, oldval) {
        document.documentElement.scrollTop = 0;
        this.crumbList = [];
        this.id = val.query.id;
        this.cid = val.query.cid;
        if (this.cid) {
        this.idList = getQueryUrl("cid", val.fullPath).split("-");
        }
        this.getCrumbs();
        this.getNavList();
      },
      // 深度观察监听
      deep: true,
    },
  },
  mounted() {
    this.id = this.$route.query.id;
    this.cid = this.$route.query.cid;
    if (this.cid) {
      this.idList = getQueryUrl("cid", this.$route.fullPath).split("-");
    }
    this.getCrumbs();
    this.getNavList();
  },
  methods: {
    getNavList() {
      navList({ aid: this.aid }).then((res) => {
        // console.log(res)
        if (res.code == 0) {
          this.navList = res.data.list;
          console.log("this.navList", this.navList);
        }
      });
    },
    getCrumbs() {
      if (this.id) {
        navinfo({ id: this.id }).then((res) => {
          if (res.code == 0) {
            console.log(11111111111);
            this.crumbList.unshift(res.data);
            console.log(this.crumbList);
          }
        });
      }
      if (this.idList.length > 0) {
        this.idList.forEach((item) => {
          navinfo({ id: item }).then((res) => {
            if (res.code == 0) {
              console.log(2222222222);
              this.crumbList.push(res.data);
              console.log(this.crumbList);
            }
          });
        });
      }
    },
    home() {
      this.$router.push(`/homepage`);
    },
    nav(item) {
      //只考虑一级导航和二级导航情况
      console.log(item);
      if (item.content_id == 0) {
        if (item.pid == 0) {
          //一级导航要判断是否有tpl_code
          if (item.tpl_code) {
            this.$router.push(
              `/${item.tpl_code}?id=${item.id}&cid=&column_id=${item.column_id}`
            );
          } else {
            //   var i1 = {};
              this.navList.forEach((i)=>{
                  if(i.id==item.id){
                    //   i1 = i;
                    console.log("iiiiiii",i)
                      this.fun1(i);
                  }
              })
            
          }
        } else if (item.pid != 0) {
          //二级导航是最后一级，必有tpl_code
          this.$router.push(
            `/${item.tpl_code}?id=${item.pid}&cid=${item.id}&column_id=${item.column_id}`
          );
        }
      } else {
        if (item.pid == 0) {
          this.$router.push(
            `/${item.tpl_code}/detail?tid=${item.content_id}&id=${item.id}&cid=&column_id=${item.column_id}`
          );
        } else if (item.pid != 0) {
          this.$router.push(
            `/${item.tpl_code}/detail?tid=${item.content_id}&id=${item.pid}&cid=${item.id}&column_id=${item.column_id}`
          );
        }
      }
    },
    fun1(item) {
    //   this.list = [];
    //   this.leaf = [];
    //   // this.list.push(this.array[0].name);
    //   // this.fun2(this.array[0]);
      if (item.url_type == 1) {
        window.open(item.url);
      } else {
          console.log(item)
          var flag = 0;
          if(item.children){
              item.children.forEach((i)=>{
                  if(flag==0&&i.url_type==2){
                      flag=1;
                        if(i.content_id==0){
                            this.$router.push(
            `/${i.tpl_code}?id=${i.pid}&cid=${i.id}&column_id=${i.column_id}`
          );
                        }else{
                           this.$router.push(
            `/${i.tpl_code}/detail?tid=${i.content_id}&id=${i.pid}&cid=${i.id}&column_id=${i.column_id}`
          );
                        }
                  }
              })
          }
        // this.fun2(item);
        // this.fun3();
      }
    },
    // fun2(item) {
    //   if (item.children && item.children.length > 0) {
    //     item.children.forEach((i) => {
    //       if (i.url_type != 1) {
    //         this.fun2(i);
    //       }
    //     });
    //   } else {
    //     this.leaf.push(item);
    //     console.log("最后叶子", item.name, this.leaf);
    //   }
    // },
    // fun3() {
    //   console.log(this.leaf);
    //   if (this.leaf.length > 0) {
    //     this.list.push(this.leaf[0]);
    //     this.fun4(this.leaf[0]);
    //   } else {
    //     this.fun6();
    //   }
    // },
    // fun4(item) {
    //   console.log("fun4",item);
    //   if (item.pid != 0) {
    //     navinfo({ id: item.pid }).then((res) => {
    //       if (res) {
    //         this.list.unshift(res.data);
    //         // console.log(res.data)
    //         this.fun4(res.data);
    //       }
    //     });
    //   } else {
    //     console.log(this.list);
    //     this.list.forEach((i) => {
    //       console.log(i.name, i.id);
    //     });
    //     this.fun5();
    //   }
    // },
    // fun5() {
    //   var id = "";
    //   var cid = "";
    //   var tpl_code = "";
    //   var content_id = "";
    //   var column_id = "";
    //   if (this.list.length > 0) {
    //     id = this.list[0].id;
    //   }
    //   if (this.list.length > 1) {
    //     cid = this.list[1].id;
    //     for (var i = 2; i < this.list.length; i++) {
    //       cid += "-";
    //       cid += this.list[i].id;
    //     }
    //   }
    //   tpl_code = this.list[this.list.length - 1].tpl_code;
    //   content_id = this.list[this.list.length - 1].content_id;
    //   column_id = this.list[this.list.length - 1].column_id;
    //   if (content_id == 0) {
    //     this.$router.push(
    //       `/${tpl_code}?id=${id}&cid=${cid}&column_id=${column_id}`
    //     );
    //   } else {
    //     this.$router.push(
    //       `/${tpl_code}/detail?tid=${content_id}&id=${id}&cid=${cid}&column_id=${column_id}`
    //     );
    //   }
    // },
  },
};
</script>
<style lang="scss">
@import "../../assets/scss/crumbs.scss";
</style>
