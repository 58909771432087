var getQueryUrl = function(variable,url){
    // if(url.indexOf('?') != -1 && url.indexOf('&') != -1) {
    if(url.indexOf('?') != -1) {
        var query = url.substring(1).split('?')[1];
        var vars = query.split("&");
        for (var i=0;i<vars.length;i++) {
                var pair = vars[i].split("=");
                if(pair[0] == variable){return pair[1];}
        }
        return(false);
    }

}
export default getQueryUrl;